.wrapper {
    width: 250px;
    max-height: 100vh;
    position: sticky;
    overflow-y: scroll;
    top: 16px;
    background-color: transparent;
    display: flex;
    padding-bottom: 117px;
    flex-direction: column;
    color: rgb(56, 56, 61);
    font-size: 14px;
    line-height: 20px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    
    &::-webkit-scrollbar {
        display: none;
    }
}

.first_wrapper {
    position: relative;
    display: flex;
    margin-bottom: 16px;
    padding: 12px 8px;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;

    a {
        display: flex;
        padding: 7px 16px;
        -webkit-box-align: center;
        align-items: center;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s ease 0s;
    }
    
}

.filter_div {
    margin-bottom: 8px;
    padding-left: 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    color: rgb(39, 39, 42);
}
