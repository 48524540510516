.modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);

    &.fadeOut {
        animation: fadeOut 0.5s;
    }
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    animation: fadeIn 0.5s;
    max-height: 90vh;
    overflow-y: scroll;
    &.fadeOut {
        animation: fadeOut 0.5s;
    }

    .modal-header {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e9e9e9;
        padding-bottom: 1rem;
        position: relative;
        h3 {
            margin-top: 20px;
            padding: 5px 0;
            padding-right: 50px;
            border-bottom: 1px dashed var(--disabled-color);
        }
        h4{
            margin: 5px 0;
        }

        .modal-close {
            cursor: pointer;
            font-size: 3rem;
            position: absolute;
            right: 0;
            top: -20px;
        }
    }

    .modal-body {
        .wrap_td{
            display: flex;
            img{
                padding: 10px;
            }
            .descrip-product{
                padding: 10px;
            }
            p, span, div{
                margin: 5px 0;
            }
            .modal-close{
                cursor: pointer;
            }
        }
        td{
            padding: 0px 15px; 
        }
    }

    .modal-footer {
        border-top: 1px solid #e9e9e9;
        padding-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            margin-top: 20px;
            width: 300px;
            height: 60px;
            font-size: 1.8rem;
            cursor: pointer;
            background: #3498db;
            color: #fff;
            border: 0.25rem solid #3498db;
            position: relative;
            z-index: 1;
            overflow: hidden;
            border-radius: 30px;
    
            &:hover {
                color: #3498db;
            }
    
            &::after {
                content: '';
                background: #ecf0f1;
                position: absolute;
                z-index: -1;
                padding: 0.85em 0.75em;
                display: block;
            }
        }
    
        button.grow_spin::after {
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            transform: scale(0, 0) rotate(-180deg);
            transition: all 0.3s ease;
        }
        button.grow_spin:hover::after {
            transform: scale(1, 1) rotate(0deg);transition: all 0.3s ease-out;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-200px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}


.quantity_setup{
    label{
        font-size: 2rem;
    }
    input{
        width: 30px;
        height: 30px;
        text-align: center;
        font-size: 1.6rem;
        border-radius: 50%;
        border: 1px dashed var(--disabled-color);
        margin: 0 5px;
    }
    .btn {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin: 0 auto;
        font-size: 1.28571em;
        background: #ebebeb;
        border-radius: 999px;
        cursor: pointer;
    }

    .btn-reduce{
        margin-left: 15px;
    }
}