.wrapper {

}

.right_header {
    color: #fff;
}

.avatar {
    img {
        width: 30px;
        border-radius: 50%;
    }
}

.drop_down_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    background: rgb(233, 240, 250);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow: hidden;

    .drop_down_content {
        overflow: hidden;
        * {
            &:hover {
                background: rgba(104, 153, 222, 0.5);
                color: rgb(94, 71, 51);
                overflow: hidden;
            }
            text-align: center;
            width: 150px;
            padding: 10px 0;
            overflow: hidden;
        }
        .block {
            display: block;
        }
    }
}