.swiper-button-prev{
    margin-left: -10px;
}
.swiper-button-next{
    margin-right: -10px;
}
.swiper-button-prev,
.swiper-button-next {
    user-select: none;
    padding: 20px;
}

.swiper-pagination-bullet {
    user-select: none;
}

.swiper.mySwiper {
    height: 100%;
}
