.wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    z-index: 2;
}

.inner {
    --width: 640px;
    background: linear-gradient(127deg, rgba(255, 0, 0, 0.8), rgba(255, 0, 0, 0) 70.71%),
        linear-gradient(336deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
        linear-gradient(217deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%);
    border-radius: 8px;
    max-width: calc(100vw - 32px);
    min-height: 600px;
    padding: 48px 16px;
    position: relative;
    width: var(--width);
}

.header {
    h1 {
        color: #fff;
    }
}

.body {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}
.form_body {
    max-width: 100%;
    width: 380px;
    color: #fff;
}
.wrapper-form {
    margin-top: 10px;
    overflow: hidden;
    width: 100%;
}
.wrapper_input {
    color: #292929;
    text-align: left;
    width: 100%;
}
.label_group {
    display: flex;
    justify-content: space-between;
    label {
        display: block;
        font-size: 1.6rem;
        font-weight: 600;
        margin: 10px 0 10px 8px;
        color: #fff;
    }
}

.input_wrap {
    background: rgba(22, 24, 35, 0.06);
    border: 1px solid rgba(22, 24, 35, 0.06);
    border-radius: 44px;
    display: flex;
    height: 44px;
    overflow: hidden;
    input {
        background-color: rgb(245, 240, 230);
        border: none;
        flex: 1 1;
        font-size: 1.4rem;
        outline: none;
        padding: 12px 20px;
    }
}
.button_form {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    border-radius: 44px !important;
    height: 44px;
    margin-top: 20px;
    width: 100%;
    font-size: 1.6rem;
    background-color: #1dbfaf;
    cursor: pointer;
    span {
        color: #fff;
    }
    &:hover .wrapper_input{
        color: yellow;
    }

}
.dont_have_acc {
    font-size: 1.6rem;
    line-height: 1.8;
    margin-bottom: 0;
    padding: 24px 16px 0;
    color: #fff;
    a {
        color: yellow!important;
        font-weight: 600;
    }
}

.footer {
    bottom: 0px;
    color: rgb(102, 102, 102);
    font-size: 1.1rem;
    left: 0px;
    line-height: 1.6;
    padding: 12px 132px;
    position: absolute;
    right: 0px;
}
