.wrapper {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 1.8rem;
}
.inner {
    padding: 10px 10px;
}
td,  th {
    vertical-align: middle;
}
td img {
    width: 100px;
    height: 100px;
}
.table-action a{
    cursor: pointer;

}