.product-images-thumb {
    max-width: 300px;
    height: 100px;
    margin: 10px 0px;
}
.product-image-wrapper {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}
.product-images-thumb img{
    width: 80%;
    height: 80%;
    border: 1px solid black;
    cursor: pointer;
}
.product-images-thumb img:hover{
    width: 100%;
    height: 100%;
}
.swiper-button-prev{
    position: absolute;
    left: 0;
    color: black;
}
.swiper-button-next{
    position: absolute;
    right: 0;
    color: black;
}
.product-main-image-wrapper {
    width: 100px;
    height: 100px;
   
}

.product-main-image-wrapper img {
    height: 80%;
    width: 80%;
}
.product-main-image-wrapper img:hover {
    height: 100%;
    width: 100%;
}



