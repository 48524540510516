.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: min((100vh - 96px) - 60px, 734px);
    min-height: 100px;
    padding-top: 8px;
    border-radius: 8px;
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 12%) 0px 2px 12px;
    overflow: hidden;
}

.search_form,
.search_result {
    width: 400px;
    
}

.search_form {
    display: flex;
    height: 40px;
    background-color: #f5f5f5;
    border-radius: 50px;
    padding: 5px 5px 5px 15px;
    position: relative;
    input {
        outline: none;
        background-color: #f5f5f5;
        border: 0px;
        flex: 1 1 0%;

        &::placeholder {
            color: rgb(32, 161, 165);
        }

        &:not(:placeholder-shown) ~ .btn-search {
            color: black;
        }
    }

    .btn-search {
        outline: none;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 0;
        background-color: var(--btn-color);
        color: white;
        cursor: pointer;
        &:active {
            background-color: rgba(79, 155, 191, 0.6);
        }
    }
}

.clear_input, .loading {
    position: absolute;
    right: 45px;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(22, 24, 35, 0.34);
    cursor: pointer;
}

.loading {
    animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
    from {
        transform: translateY(-50%) rotate(0);
    }
    to {
        transform: translateY(-50%) rotate(360deg);
    }
}