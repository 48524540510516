.wrapper {
    margin-bottom: 20px;
    padding: 12px 15px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.header-cart {
    align-self: start;
    padding: 20px;
    margin-bottom: 10px;
    .link-header {
        color: var(--disabled-color);
        margin-right: 10px;
    }
    span:last-child {
        margin-left: 5px;
    }
}

.bot-cart {
    th{
        padding: 10px;
    }

    .total {
        max-width: 90px;
    }
    .wrap_td {
        display: flex;
        img {
            padding: 10px;
        }
        .descrip-product {
            padding: 10px;
            max-width: 400px;
        }
        p,
        span,
        div {
            margin: 5px 0;
        }
        .modal-close {
            cursor: pointer;
        }
    }
    td {
        padding: 10px 15px;
    }
}

.quantity_setup {
    display: flex;

    label {
        font-size: 2rem;
    }
    input {
        width: 30px;
        height: 30px;
        text-align: center;
        font-size: 1.6rem;
        border-radius: 50%;
        border: 1px dashed var(--disabled-color);
        margin: 0 5px;
    }
    .btn {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin: 0 auto;
        font-size: 1.28571em;
        background: #ebebeb;
        border-radius: 999px;
        cursor: pointer;
    }

    .btn-reduce {
        margin-left: 15px;
    }
}

.cart-footer {
    display: flex;
    justify-content: flex-end;

    button {
        margin-left: 15px;
        margin-top: 20px;
        width: 300px;
        height: 50px;
        font-size: 1.8rem;
        cursor: pointer;
        background: #3498db;
        color: #fff;
        border: 0.25rem solid #3498db;
        position: relative;
        z-index: 1;
        overflow: hidden;
        border-radius: 50px;
        &:hover {
            color: #3498db;
        }

        &::after {
            content: '';
            background: #ecf0f1;
            position: absolute;
            z-index: -1;
            padding: 0.85em 0.75em;
            display: block;
        }
    }

    button.grow_spin::after {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transform: scale(0, 0) rotate(-180deg);
        transition: all 0.3s ease;
    }
    button.grow_spin:hover::after {
        transform: scale(1, 1) rotate(0deg);
        transition: all 0.3s ease-out;
    }
}
