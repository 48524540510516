.wrapper {
    width: 100%;
    height: var(--default-layout-header-height);
    display: flex;
    justify-content: center;
    padding: 8px 0px;
}

.inner {
    height: 70%;
    width: var(--default-layout-width);

    box-shadow: 0px 1px 1px rgb(0 0 0 / 12%);
    margin: 0 auto;
}

.up_header {
    height: 100%;
    display: flex;
    align-items: center;
}

.up_left_header {
    display: flex;
    // flex: 1 1 0%;
    -webkit-box-align: center;
    align-items: center;
}
.logo-link {
    margin-right: 48px;
}

.up_right_header {
    display: flex;
    justify-content: center;
    flex: 1 2;
    .nav_link {
        margin-right: 50px;
    }
    .nav_link_logo {
        margin-right: 20px;
        position: relative;
        &:hover .logo_number {
            color: var(--bs-link-hover-color);
        }
    }

}

.up_last_header {
    position: relative;

    .nav_link {
        margin-left: 20px;
    }
    .nav_link1 {
        margin-left: 20px;
        max-width: 10px;
        white-space: nowrap;
        overflow: visible !important;
        text-overflow: ellipsis; // This is where the magic happens
        color: red;
    }
    .username {
        overflow: hidden;
        max-width: 200px;
        white-space: nowrap;
        color: #0dcaf0;
        cursor: default;
    }

    .nav_link:last-child::after {
        content: '';
        position: absolute;
        left: 50%;
        width: 1px;
        height: 20px;
        background-color: rgba(22, 24, 35, 0.12);
    }
    .nav_link1:last-child::after {
        content: '';
        position: absolute;
        left: 55%;
        width: 1px;
        height: 20px;
        background-color: rgba(22, 24, 35, 0.12);
    }
}

.drop_down_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    background: rgb(233, 240, 250);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow: hidden;

    .drop_down_content {
        overflow: hidden;
        * {
            &:hover {
                background: rgba(104, 153, 222, 0.5);
                color: rgb(94, 71, 51);
                overflow: hidden;
            }
            text-align: center;
            width: 150px;
            padding: 10px 0;
            overflow: hidden;
        }
        .block {
            display: block;
        }
    }
}

.bottom_header {
    height: 60%;
    position: relative;
    display: flex;
    justify-content: space-between;
    line-height: 1.15;

    h4 {
        font-weight: bold !important;
        line-height: 150%;
    }

    .bottom_left_header {
        margin-left: 105px;
        margin-top: 8px;
        display: flex;
        .nav_link {
            margin-right: 40px;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;

            white-space: nowrap;
        }
    }

    .bottom_right_header {
        margin-top: 8px;
        display: flex;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        margin-right: 40px;
        white-space: nowrap;
    }
    .bottom_right_header > * {
        margin-right: 10px;
    }
}
