.image-number {
    position: relative;
}

.image_number_orange {
    position: absolute;
    background-color: #2a9dcc;
    color: #fff;
    border-radius: 50%;
    font-size: 10px;
    font-weight: 500;
    min-width: 20px;
    padding: 2px 0;
    right: 0px;
    top: 0px;
    text-align: center;
}

td{
    img{
        width: 80px;
        height: 80px;
    }
}

.detail{
    color: var(--disabled-color);
    font-size: 2rem;
    font-weight: 600;
    margin: 15px 0;
}

.detail_text {
    color: #ff7526;;
    font-size: 1.8rem;
    font-weight: 600;
}

button.back {
    margin-left: 15px;
    margin-top: 20px;
    width: 300px;
    height: 50px;
    font-size: 1.8rem;
    cursor: pointer;
    background: #3498db;
    color: #fff;
    border: 0.25rem solid #3498db;
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: 50px;
    &:hover {
        color: #3498db;
    }

    &::after {
        content: '';
        background: #ecf0f1;
        position: absolute;
        z-index: -1;
        padding: 0.85em 0.75em;
        display: block;
    }
}

button.grow_spin::after {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: scale(0, 0) rotate(-180deg);
    transition: all 0.3s ease;
}
button.grow_spin:hover::after {
    transform: scale(1, 1) rotate(0deg);
    transition: all 0.3s ease-out;
}