@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500;600;700;800&display=swap');

:root {
    --primary: #fe2c55;
    --black: #000;
    --text-color: #333;
    --white: #fff;

    --font-family: 'Nunito', sans-serif;

    --default-layout-header-height: 88px;
    --default-layout-width: 1440px;

    --btn-color: #4f9bbf;
    // --swiper-navigation-color: blue;
    // --swiper-theme-color: red;
    --disabled-color: #9c9c9c;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5% !important;
}

body {
    font-family: var(--font-family)!important;
    font-size: 1.6rem!important;
    line-height: 1.5!important;
}

.swal2-popup {
    font-size: 1.6rem !important;
    font-weight: 700;
}

button,
input,
[tabindex] {
    outline: none;
    border: none;
}

a[href] {
    color: var(--text-color);
    text-decoration: none;
}

.text {
    &:hover {
        text-decoration: underline;
    }
}

li {
    list-style-type: none;
}

button,
input[type='submit'] {
    user-select: none;
}

.f-spaceb {
    display: flex;
    justify-content: space-between;
}

.f-spaceb-align {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.f-center-align {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-center {
    text-align: center;
}

.color-disabled {
    color: var(--disabled-color);
}

img{
    vertical-align:unset !important;
}

.logo_number_orange {
    position: absolute;
    background-color: #ff7526;
    color: #fff;
    border-radius: 50%;
    font-size: 10px;
    font-weight: 500;
    min-width: 20px;
    padding: 2px 0;
    right: -15px;
    top: -8px;
    text-align: center;
}