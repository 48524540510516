.wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(208 , 222, 222, 0.6);
    z-index: 99999;
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
    color: white;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}
